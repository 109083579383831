// extracted by mini-css-extract-plugin
export var accentColor = "#e10600";
export var banner = "parallax-image-module--banner--74b7e";
export var bgImage = "parallax-image-module--bgImage--96d7e";
export var container = "parallax-image-module--container--f33ee";
export var dividerColor = "#e4e4e4";
export var dividerWhiteColor = "#fff";
export var layer = "parallax-image-module--layer--cfabe";
export var logoContainer = "parallax-image-module--logoContainer--a0c31";
export var primaryColor = "#fff";
export var secondaryColor = "#000";
export var text = "parallax-image-module--text--9451b";
export var textDisabledColor = "#ccc";
export var textPrimaryColor = "#101820";
export var textSecondaryColor = "#fff";