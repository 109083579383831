import React from "react";
import * as styles from "../styles/components/link-arrow.module.scss"
import StyledText, {TextStyles} from "./typography";
import { Link } from "gatsby";
import {IconArrow} from "./svg";

 type LinkArrowColorType = 'primary' | 'secondary' | 'accent';

type Props = {
  href?: string,
  children: React.ReactNode,
  className?: string;
  textVariant?: TextStyles;
  color?: LinkArrowColorType;
};

const LinkArrow = (props: Props) => {
  const {href='#', children, className, textVariant, color = 'primary', ...res} = props;
  const classes: string[]= [styles.root, className ? className : '', styles[color]];

  return (
    <Link
      className={classes.join(' ')}
      to={href}
      {...res}
    >
      <div className={styles.before}>
        <IconArrow />
      </div>

      <StyledText variant={textVariant ? textVariant : TextStyles.buttonArrow} tag='span'>{children}</StyledText>
      <div className={styles.after}>
        <IconArrow />
      </div>
    </Link>
  )
}

export default LinkArrow
