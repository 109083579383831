// extracted by mini-css-extract-plugin
export var accent = "link-arrow-module--accent--c063f";
export var accentColor = "#e10600";
export var after = "link-arrow-module--after--0f222";
export var arrowAnimation = "link-arrow-module--arrowAnimation--8adc9";
export var before = "link-arrow-module--before--73d12";
export var dividerColor = "#e4e4e4";
export var dividerWhiteColor = "#fff";
export var primary = "link-arrow-module--primary--d0da5";
export var primaryColor = "#fff";
export var root = "link-arrow-module--root--9b397";
export var secondary = "link-arrow-module--secondary--c74b5";
export var secondaryColor = "#000";
export var textDisabledColor = "#ccc";
export var textPrimaryColor = "#101820";
export var textSecondaryColor = "#fff";