// extracted by mini-css-extract-plugin
export var accentColor = "#e10600";
export var arrowAnimation = "cta-list-module--arrowAnimation--1f2be";
export var box = "cta-list-module--box--856a1";
export var content = "cta-list-module--content--593cf";
export var description = "cta-list-module--description--7ec82";
export var dividerColor = "#e4e4e4";
export var dividerWhiteColor = "#fff";
export var grid = "cta-list-module--grid--1bb79";
export var link = "cta-list-module--link--13658";
export var primaryColor = "#fff";
export var secondaryColor = "#000";
export var textDisabledColor = "#ccc";
export var textPrimaryColor = "#101820";
export var textSecondaryColor = "#fff";