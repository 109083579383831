// extracted by mini-css-extract-plugin
export var accentColor = "#e10600";
export var category = "map-module--category--01cf2";
export var categoryContainer = "map-module--categoryContainer--1054f";
export var categoryGrid = "map-module--categoryGrid--8caf7";
export var container = "map-module--container--8725e";
export var dividerColor = "#e4e4e4";
export var dividerWhiteColor = "#fff";
export var dot = "map-module--dot--0d1d8";
export var hover = "map-module--hover--a76e4";
export var image = "map-module--image--ba11e";
export var imageBorder = "map-module--imageBorder--cad5f";
export var imageContainer = "map-module--imageContainer--22fd0";
export var label = "map-module--label--2b221";
export var mapContainer = "map-module--mapContainer--83ffe";
export var mobileTitle = "map-module--mobileTitle--33adb";
export var primaryColor = "#fff";
export var secondaryColor = "#000";
export var selected = "map-module--selected--074f4";
export var text = "map-module--text--00a14";
export var textDisabledColor = "#ccc";
export var textPrimaryColor = "#101820";
export var textSecondaryColor = "#fff";
export var title = "map-module--title--06648";