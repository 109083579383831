import React, {useEffect} from "react";
import Parallax from 'parallax-js'
import * as styles from "../../styles/components/parallax-image.module.scss";
import MountDown from "../../assets/images/Mount_down.png";
import MountUp from "../../assets/images/Mount_up.png";
import Sky from "../../assets/images/Sky.png";
import Transcarpathia from "../../assets/images/Transcarpathia.png";
import StyledText, {TextStyles} from "../typography";
import {IconLogoStep4} from "../svg";

export const ParallaxImage = ({label}: {label: string}) => {
    useEffect(() => {
        const scene = document.getElementById('scene');
        const parallaxInstance = new Parallax(scene);
    }, [])

    return (
        <section className={styles.banner}>
            <div className={styles.logoContainer}>
                <IconLogoStep4 />
            </div>

            <div className={styles.container}>
                <div id="scene">
                    <img src={Transcarpathia} alt='Transcarpathia' className={styles.bgImage}/>
                    <div className={styles.layer} data-depth='-0.2'>
                        <img src={Sky} alt='Sky'/>
                    </div>
                    <div className={styles.layer} data-depth='0.1'>
                        <StyledText variant={TextStyles.h1} className={styles.text}>
                            {label}
                        </StyledText>
                    </div>
                    <div className={styles.layer} data-depth='-0.1'>
                        <img src={MountUp} alt='MountUp'/>
                    </div>
                    <div className={styles.layer} data-depth='0.1'>
                        <img src={MountDown} alt='MountDown'/>
                    </div>
                </div>
            </div>
        </section>
    )
}