import React from "react";
import * as styles from "../../styles/components/cta-list.module.scss"
import {CtaBox} from "../../store/models/cta";
import StyledText, {TextStyles} from "../typography";
import LinkArrow from "../LinkArrow";
import { navigate } from "gatsby"

type CtaListProps = {
    ctaList: CtaBox[] | null,
}

export const CtaList = ({ctaList}: CtaListProps) => {
    return (
        <div className={styles.grid}>
            {ctaList?.map((item: CtaBox) => (
                <div className={styles.box}>
                    <div className={styles.content} onClick={() => navigate(item.link?.link || '#')}>
                        <StyledText variant={TextStyles.h2} className={styles.description}>
                            {item?.description}
                        </StyledText>
                        {item.link?.link &&
                          <LinkArrow href={item.link?.link} className={styles.link} textVariant={TextStyles.link}>
                              {item.label}
                          </LinkArrow>
                        }
                    </div>
                </div>
            ))}
        </div>
    )
}