import * as styles from "../../styles/components/map.module.scss";
import React, {ComponentPropsWithoutRef, createElement, createRef, RefObject, useEffect, useRef} from "react";
import {MapPoints} from "../../store/models/transcarpathia-page";

export const MapPoint = ({point}: {point: MapPoints}) => {
    const containerRef = useRef<SVGGElement>(null);
    const textRef = useRef<SVGTextElement>(null);
    const dotRef = useRef<SVGTextElement>(null);

    useEffect(() => {
        let rect: any;
        if (textRef.current && containerRef.current && dotRef.current) {

            const box: DOMRect = textRef.current.getBBox();
            let endX = box.width + 55 || 0;
            let endY = box.height + 10 || 0;

            rect = document.createElementNS("http://www.w3.org/2000/svg", "rect");
            rect.setAttribute("x", '-40');
            rect.setAttribute("y", '-24');
            rect.setAttribute("width", endX.toString());
            rect.setAttribute("height", endY.toString());
            rect.setAttribute("fill", '#FFF');

            textRef.current.setAttribute("transform", 'translate(0, 0)')
            dotRef.current.setAttribute("transform", 'translate(-30,-14)')

            if (point.coordinates?.x && +point.coordinates?.x > 1500) {
                containerRef.current.setAttribute("transform", `translate(${+point.coordinates?.x - endX}, ${point.coordinates?.y})`)
                dotRef.current.setAttribute("transform", `translate(${endX - 65}, -14)`)
                textRef.current.setAttribute("transform", 'translate(-20, 0)')
            }

            containerRef.current.prepend(rect)
        }

        return () => {
            rect.remove();
        }
    }, [point])

    return (
        <g>
            <g ref={containerRef} transform={`translate(${point.coordinates?.x},${point.coordinates?.y})`} className={styles.hover}>
                <text ref={textRef} className={styles.text}>{point.label}</text>
                <g ref={dotRef} transform='translate(-30,-14)'>
                    <rect className={styles.dot}></rect>
                </g>
            </g>

            <g transform='translate(1350,300)' className={styles.imageContainer}>
                <rect className={styles.imageBorder} x={-10} y={295}></rect>
                <rect className={styles.imageBorder} x={295} y={-10}></rect>
                <image className={styles.image} href={point.image?.fullUrl}/>
            </g>
        </g>
    )
}