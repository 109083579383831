import React from "react";
import {Layout} from "../components/Layout";
import * as styles from "../styles/pages/transcarpathia-page.module.scss"
import {TranscarpathiaPageModel} from "../store/models/transcarpathia-page";
import StyledText, {TextStyles} from "../components/typography";
import {CtaList} from "../components/cta/CtaList";
import {Map} from "../components/transcarpathia/Map";
import ContentContainer from "../components/ContentContainer";
import {ParallaxImage} from "../components/transcarpathia/ParallaxImage";
import SEO from "../components/SEO";
import StrapiClient, {StrapiResponse, StrapiSingleTypes} from "../strapi-client/strapi-client";
import {MenuModel} from "../store/models/menu";
import {GetServerDataProps, GetServerDataReturn} from "gatsby";

type TranscarpathiaPageProps = {
    serverData: {
        menu: StrapiResponse,
        page: StrapiResponse
    }
}

const TranscarpathiaPage = (props: TranscarpathiaPageProps) => {
    const transcarpathiaPageModel: TranscarpathiaPageModel = TranscarpathiaPageModel.fromJSON(props.serverData.page);
    const menuModel: MenuModel = MenuModel.fromJSON(props.serverData.menu);

    return (
        <Layout noContainer absoluteHeader menu={menuModel}>
            <SEO seo={transcarpathiaPageModel.seo}></SEO>
            <ParallaxImage label={transcarpathiaPageModel.imageLabel || ''} />

            <ContentContainer>
                <div className={styles.listGrid}>
                    {transcarpathiaPageModel.list?.map(item => (
                        <div className={styles.listItem}>
                            <div>
                                <img
                                    src={item.icon?.fullUrl}
                                    alt={item.icon?.alt}
                                />
                            </div>
                            <StyledText variant={TextStyles.h5}>
                                {item?.text}
                            </StyledText>
                        </div>
                    ))}
                </div>
            </ContentContainer>

            <div className={styles.overflow}>
                {transcarpathiaPageModel.mapCategories && (
                    <Map mapCategories={transcarpathiaPageModel.mapCategories} mapTitle={transcarpathiaPageModel.mapTitle || ''}/>
                )}

                <ContentContainer>
                    <CtaList ctaList={transcarpathiaPageModel.ctaList}/>
                </ContentContainer>
            </div>

        </Layout>
    )
}

export default TranscarpathiaPage;

export async function getServerData(context: GetServerDataProps): GetServerDataReturn  {
    try {
        const locale: string = context.pageContext.locale as string;
        const strapi = new StrapiClient(locale);
        const page = await strapi.getSingleType(StrapiSingleTypes.transcarpathiaPage);
        const menu = await strapi.getSingleType(StrapiSingleTypes.menu);

        return {
            props: {
                page,
                menu
            },
        }
    } catch (error) {
        return {
            status: 500,
            headers: {},
            props: {}
        }
    }
}
