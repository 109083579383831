import * as styles from "../../styles/components/map.module.scss";
import StyledText, {TextStyles} from "../typography";
import ContentContainer from "../ContentContainer";
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {RootState} from "../../store/reducers";
import {MapCategories, MapPoints, TranscarpathiaPageModel} from "../../store/models/transcarpathia-page";
import {IconCastle, IconStar, IconTree} from "../svg";

const renderIcon = (index: number) => {
    switch (index) {
        case 0:
            return <IconCastle/>
        case 1:
            return <IconTree/>
        case 2:
        default:
            return <IconStar/>
    }
}

export type MapNavProps = {
    mapCategories: MapCategories[],
    setPoints: (value: MapPoints[]) => void,
    mapTitle: string
};

export const MapNav = (props: MapNavProps) => {
    const { setPoints, mapCategories, mapTitle } = props;

    const [categoryIndex, setCategoryIndex] = useState<number>(0);

    useEffect(() => {
        if (!mapCategories) return
        setPoints(mapCategories[categoryIndex]?.mapPoints || [])
    }, [categoryIndex, mapCategories])

    return (
        <ContentContainer>
            <StyledText variant={TextStyles.h1} className={styles.mobileTitle}>
                {mapTitle}
            </StyledText>
            <div className={styles.categoryContainer}>
                <StyledText variant={TextStyles.h1} className={styles.title}>
                    {mapTitle}
                </StyledText>
                <div className={styles.categoryGrid}>
                    {mapCategories?.map((category, index ) => (
                        <div className={`${styles.category} ${categoryIndex === index ? styles.selected : ''}`} onClick={() => setCategoryIndex(index)}>
                            {renderIcon(index)}
                            <StyledText variant={TextStyles.h5} className={styles.label}>
                                {category?.label}
                            </StyledText>
                        </div>
                    ))}
                </div>
            </div>
        </ContentContainer>
    )
}
