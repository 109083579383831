import React, {useState} from "react";
import * as styles from "../../styles/components/map.module.scss";
import {IconMap} from "./IconMap";
import {MapCategories, MapPoints} from "../../store/models/transcarpathia-page";
import {MapPoint} from "./MapPoint";
import {MapNav} from "./MapNav";

export type MapProps = {
    mapCategories: MapCategories[],
    mapTitle: string
};

export const Map = (props: MapProps) => {
    const { mapCategories, mapTitle } = props;
    const [points, setPoints] = useState<MapPoints[]>([]);

    return (
        <div className={styles.container}>
            <MapNav mapTitle={mapTitle} setPoints={setPoints} mapCategories={mapCategories || []}/>

            <div className={styles.mapContainer}>
                <IconMap>
                    {points && points?.map((point) => (
                        <MapPoint point={point}/>
                    ))}
                </IconMap>
            </div>
        </div>
    )
}
